import React, { useEffect } from "react"
import { Col, message, Row, Spin } from "antd"

import CustomBreadcrumb from "../../components/commonBreadcrumb"
import Template from "../../layouts/base"
import { navigate, useIntl } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"
import UserForm from "../../components/user/userForm"
import useStateObject from "../../hooks/useStateObject"
import { getUser, refreshSession } from "../../services/auth"
import { getUserPermissions } from "../../services/auth/permissions/userPermissions"
import arrayUtils from "../../utils/array-utils"

const UserEdit = ({ location }) => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    userId: location && location.pathname.split("/").pop(),
    user: null,
    processing: false,
  })

  useEffect(() => {
    getUser().then(loggedUser => {
      authFetch(`${process.env.GATSBY_CONF_API_URL}/users/${state.userId}`)
        .then(response => {
          response.json().then(user => {
            if (200 !== response.status) {
              message.error(
                intl.formatMessage({
                  id: "page-user-get-detail-error-text",
                })
              )
            } else {
              setState({ user, loggedUser })
            }
          })
        })
        .catch(reason => {
          message.error(
            intl.formatMessage({
              id: "page-user-get-detail-error-text",
            })
          )
          throw new Error(`[ERROR] Retrieving user detail: ${reason}`)
        })
    })
  }, [])

  const goBack = () => {
    navigate(`/user/${state.userId}`)
  }

  const updateUserEmitter = () => {
    setState({ processing: true })
    authFetch(`${process.env.GATSBY_CONF_API_URL}/users/${state.userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: state.user,
    })
      .then(response => {
        setState({ processing: false })
        if (200 !== response.status) {
          message.error(
            intl.formatMessage({ id: "page-user-update-error-text" })
          )
        } else {
          message.success(
            intl.formatMessage({ id: "page-user-update-ok-text" })
          )
          if (
            state.loggedUser.id === state.userId &&
            !arrayUtils.equals(state.loggedUser.groups, state.user.roles)
          ) {
            refreshSession(async () => {
              await getUserPermissions()
            })
          }
          navigate(`/user/${state.userId}`)
        }
      })
      .catch(() => {
        setState({ processing: false })
        message.error(intl.formatMessage({ id: "page-user-update-error-text" }))
      })
  }

  return (
    <Template selected={["user", "user-edit"]}>
      <CustomBreadcrumb
        crumbs={[
          {
            label: intl.formatMessage({ id: "menu-user-management" }),
            link: "/user",
          },
          intl.formatMessage({ id: "menu-user-update" }),
        ]}
      />
      <Row>
        <Col className="knolar-intro">
          {intl.formatMessage({ id: "menu-user-update" })}
        </Col>
      </Row>
      <div className="content">
        {state.user ? (
          <Row>
            <UserForm
              user={state.user}
              isEditView={true}
              onCancel={goBack}
              onSubmit={updateUserEmitter}
              processing={state.processing}
            />
          </Row>
        ) : (
          <Row
            style={{
              height: "200px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </Row>
        )}
      </div>
    </Template>
  )
}

export default withAuth(UserEdit)
